import React, { useState, useEffect } from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import ERC20Token from "../contracts/ERC20Token";
import OfferContract from "../contracts/Offer";
import { Button, Table, Pagination } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import {PAGE_SIZE} from "../helper/utils";

export default function DataTable({
  userContract,
  columns,
  data,
  filterToken,
}) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [{ id: '4' }]
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const [pageList, setPageList] = useState([]);

  useEffect(async () => {
    setPageSize(PAGE_SIZE);
  }, []);

  useEffect(async () => {
    let pageArray = [];
    let maxPage = pageCount;
    let minPage = pageIndex + 1;
    const pageRange = 2; // number of pages to show on each side
    if (pageIndex + pageRange < pageCount) {
      maxPage = pageIndex + pageRange + 1;
    }
    // figure out min page

    if (pageIndex <= pageRange) {
      minPage = 1;
    } else if (maxPage - minPage > pageRange) {
      minPage = maxPage - pageRange;
    } else {
      minPage = pageIndex - 1;
    }

    for (let number = minPage; number <= maxPage; number++) {
      pageArray.push(number);
    }
    setPageList(pageArray);
  }, [pageIndex, pageCount]);

  return (
    <>
      <div className="market-body-content">
        <Table className="market-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProperties} = cell.getCellProps()
                    return (
                      <td {...restCellProperties} key={key}>{cell.render("Cell")}</td>
                    );
                  })}

                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {page.length === 0 &&
      <>
        <div className="market-head-content mt-2 intro-text text-center">
          Can't see any offers? Try a different RPC
          <div className="market-body text-sm text-center">
            https://rpc.foxswap.fi/<br />
            https://api.harmony.one/<br />
            <>https://harmony-0-rpc.gateway.pokt.network/</><br />
            <>https://api.s0.t.hmny.io/</><br />
            <>https://a.api.s0.t.hmny.io/</><br />
            <>https://iad.api.harmony.one/</><br />
            <>https://api.fuzz.fi/</><br />
            <>https://rpc.heavenswail.one/</><br />
            <>https://rpc.hermesdefi.io/</><br />
            <>https://rpc.cosmicuniverse.one/</><br />
            <>https://rpc.onechain.services/</><br />
          </div>
        </div>
      </>
      }

      {!userContract && (
          <Pagination className="pt-3">
            <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
            ><b>{1}</b></Pagination.First>
            <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
            />
            {pageList.map((pageNumber) => (
                <Pagination.Item
                    active={pageNumber == pageIndex + 1}
                    onClick={() => gotoPage(pageNumber - 1)}
                >
                  {pageNumber}
                </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
            <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
            ><b>{pageCount}</b></Pagination.Last>
          </Pagination>
      )}
    </>
  );
}
