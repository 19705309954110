import LockedTokenLensABI from './abi/LockedTokenLensABI.json'
import { ethers } from 'ethers'
import { Contract } from 'ethers-multicall'
import { initMultiCall } from './multicall'

const BATCH_SIZE = 800
const N_LOOPS = 9
const START_COUNT_IDX = 4100

export default class LockedTokenLens {
    constructor(address, provider) {
        this.contract = new ethers.Contract(address, LockedTokenLensABI, provider)
    } 
  
    getAllActiveOfferInfo = async (offerFactory, start_count_idx = START_COUNT_IDX) => {
        let idx = 0
        let output = [[], [], [], [], []]
        try{
            for (var i = 0; i < N_LOOPS; i++) {
                const resp = await this.contract.getAllActiveOfferInfos(offerFactory, idx, idx + BATCH_SIZE, START_COUNT_IDX)
                output[0] = [...output[0], ...resp[0]]
                output[1] = [...output[1], ...resp[1]]
                output[2] = [...output[2], ...resp[2]]
                output[3] = [...output[3], ...resp[3]]
                output[4] = [...output[4], ...resp[4]]
                console.log('Loaded', BATCH_SIZE, 'more. Total offers =', output[0].length)
                idx += BATCH_SIZE
            }
        } catch(err) {
            console.log(err)
        }
        return output
    }

    getOfferInfo = async (offerFactory)=> {
        return await this.contract.getOfferInfo(offerFactory)
    }
}