import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers-multicall";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import logo from "../assets/images/foxswap.svg";
import CreatOfferModal from "../components/modal/CreatOfferModal";
import ERC20Token from "../contracts/ERC20Token";
import LockedTokenLens from "../contracts/LockedTokenLens";
import OfferContract from "../contracts/Offer";
import {
  ZERO_ADDRESS,
  contractAddress,
  map as addressToContract, numberWithCommas,
} from "../helper/utils";
import Row from "./Row";
import OfferABI from "../contracts/abi/OfferABI.json";
import { initMultiCall } from "../contracts/multicall";
import OfferFactory from "../contracts/OfferFactory";
import DataTable from "./DataTable";
import ReactTooltip from "react-tooltip";
import InsufficientStableModal from "./modal/InsufficientStableModal";


function filterOfferData(offeresData, sellersAddress, account) {
  if (offeresData.length === 0 || sellersAddress.length === 0) return offeresData;
  const userOffer = [];
  for (let i = 0; i < offeresData.length; i++) {
    if (sellersAddress[i].toLowerCase() === account.toLowerCase()) {
      const offerTemp = offeresData[i];
      offerTemp.isUserOffer = true;
      userOffer.push(offerTemp);
    }
  }
  return userOffer;
}

const DEFAULT_OFFER = {
  lockedTokens: '0x',
  offerAddresses: '0x',
  lockedBalances:'0',
  tokenWanted: 'NA',
  stableCoin: 'NA',
  amountWantedInWei: '0',
  amountWanted: '0',
  pricePerToken: 1,
  lockedToken: contractAddress.lockedTokens[0],
  tokenWantedSymbol: 'NA',
  isUserOffer: false
}

const DEFAULT_INFSUFF_STABLE = {
  active: false,
  stableSymbol: "",
  needed: 0,
  wallet: 0
}

function ViewMarket() {
  const [modalShow, setModalShow] = React.useState(false);
  const { library, account } = useWeb3React();
  const [activeoffers, setActiveOffer] = useState([]);
  const [filterActiveoffers, setFilterActiveOffer] = useState([]);
  const [userActiveoffers, setUserActiveOffer] = useState([]);
  const [filterUserActiveoffers, setFilterUserActiveOffer] = useState([]);
  const [totalVolume, setTotalVolume] = useState("");
  const [offeresData, setOffersData] = useState([]);
  const [sellersAddress, setSellersAddress] = useState([]);
  const [sortStatus, setSortStatus] = useState("Filter: JEWEL");
  const [filterToken, setFilterToken] = useState();
  const [insufficientStable, setInsufficientStable] = useState(DEFAULT_INFSUFF_STABLE);

  const fetchTotalVolume = async () => {
    try {
      const factory = new OfferFactory(
          contractAddress.offerFactory,
          library.getSigner()
      );
      const totalVolume = await factory.totalVolume();
      setTotalVolume(totalVolume);
    } catch(err) {
      console.log('Error fetchTotalVolume:', err)
    }
  };

  const fetchData = async () => {
    try {
      const lockedTokenLens = new LockedTokenLens(
        contractAddress.lockedTokenLens,
        library.getSigner()
      );
      let data = await lockedTokenLens.getAllActiveOfferInfo(
        contractAddress.offerFactory
      );

      let activeoffers_local = [];
      for (let i = 0; i < data[0].length; i++) {
        if (data[0][i] !== ZERO_ADDRESS) {
          const lockedToken = addressToContract.get(data[0][i].toLowerCase());
          const lockedBalance = ERC20Token.getFromWei(data[2][i].toString(), 18);
          // console.log(i, lockedToken, lockedBalance);
          const { decimals: tokenWantedecimal, symbol: tokenWantedSymbol } =
            addressToContract.get(data[3][i].toLowerCase());
          const amountwanted = ERC20Token.getFromWei(
            data[4][i].toString(),
            tokenWantedecimal
          );
          const pricePerToken = (amountwanted / lockedBalance).toFixed(10);
          activeoffers_local.push({
            lockedTokens: data[0][i],
            offerAddresses: data[1][i],
            lockedBalances: lockedBalance.toString(),
            tokenWanted: data[4][i].toString(),
            stableCoin: data[3][i].toLowerCase(),
            amountWantedInWei: data[4][i].toString(),
            amountWanted: amountwanted.toString(),
            pricePerToken,
            lockedToken,
            tokenWantedSymbol,
            isUserOffer: false
          });
        }
      }
      setOffersData(activeoffers_local);
      setActiveOffer(activeoffers_local);
      setFilterActiveOffer(activeoffers_local);

      let sellers = activeoffers_local.map((ele) => {
        const contract = new Contract(ele.offerAddresses, OfferABI);
        return contract.seller();
      });
      const callInstance = await initMultiCall();
      const allSellers = await callInstance.all(sellers)
      setSellersAddress(allSellers);

      const tempUserData = filterOfferData(activeoffers_local, allSellers, account)
      setUserActiveOffer(tempUserData)
      setFilterUserActiveOffer(tempUserData);
      // setUserActiveOffer(tempUserData);
      const filterActiveData = activeoffers_local.filter((contract) => {
          return contract.lockedToken.name === 'JEWEL';
      });
      setFilterActiveOffer(filterActiveData);
    } catch(err) {
      console.log('Error fetchTotalVolume:', err)
    }
  };

  const onHide = (isSuccess = false) => {
    if (isSuccess) fetchData(); fetchTotalVolume();
    setModalShow(false);
  };

  const onHideInsufficientStable = () => {
    setInsufficientStable(DEFAULT_INFSUFF_STABLE);
  };

  const onClick = async (offer) => {
    if (offer.isUserOffer) {
      try {
        // setLoading({ disabledButton: index, status: true });
        // setBtnText({ index, text: "Please wait...." });
        const offerContract = new OfferContract(
            offer.offerAddresses,
            library.getSigner()
        );
        await offerContract.cancel();
        // setBtnText({ index, text: "Canceled" });
      } catch (err) {
        alert(JSON.stringify(err));
        console.log(err);
        // setBtnText({ index, text: prevText });
      }

      // setLoading({ disabledButton: index, status: false });
    } else {
      const stableCoin = new ERC20Token(offer.stableCoin, library.getSigner());
      const approvalAmt = await stableCoin.getAllowance(account, offer.offerAddresses);
      // First check the balance of user
      const userBal = await stableCoin.getTokenBalance(account);
      if (userBal < Number(offer.amountWanted)) {
        // TODO - pop-up Insufficient Stablecoin
        setInsufficientStable({
          active: true,
          stableSymbol: offer.tokenWantedSymbol,
          needed: Number(offer.amountWanted),
          wallet: userBal
        });
        console.log('Insufficient stablecoin', userBal, Number(offer.amountWanted));
        return;
      }

      try {
        // Run 2 txs for approving and buying

        if (approvalAmt < Number(offer.amountWantedInWei)) {
          // First approve if needed
          const erc20 = new ERC20Token(
              offer.stableCoin,
              library.getSigner()
          );
          await erc20.approve(
              offer.offerAddresses,
              offer.amountWantedInWei
          );
        }
        const offerContract = new OfferContract(
            offer.offerAddresses,
            library.getSigner()
        );
        await offerContract.fill();
      } catch (err) {
        alert(JSON.stringify(err));
        console.log(err);
        // setBtnText({ index, text: prevText });
      }
      // setLoading({ disabledButton: index, status: false });
    }
  }

  const handleSelect = (e) => {
    console.log('handleSelect', e);
    const tempUserData = userActiveoffers;
    const activeData = activeoffers;
    setSortStatus(e);
    const filterString = e.split(" ")[1];
    setFilterToken(e.split(" "[1]));
    const filterActiveData = activeData.filter((contract) => {
      if (filterString.toLowerCase() == "All".toLowerCase()) {
        return true;
      } else {
        return contract.lockedToken.name === filterString;
      }
    });
    setFilterActiveOffer(filterActiveData);

    const filterUserData = tempUserData.filter((contract) => {
      if (filterString.toLowerCase() == "All".toLowerCase()) {
        return true;
      } else {
        return contract.lockedToken.name === filterString;
      }
    });
    setFilterUserActiveOffer(filterUserData);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "CONTRACT",
        id: 'contract-token',
        accessor: (d) => d.offerAddresses,
        Cell: ({ row: { original } }) => (
            <Button
                className="button-invis"
                data-tip="copy"
                data-for={`registerTip-${original.offerAddresses}`}
                data-event='click focus'
            >
              <ReactTooltip
                  id={`registerTip-${original.offerAddresses}`}
                  delayHide={1000}
                  afterShow={() => {
                    // console.log("clicked", original.offerAddresses)
                    navigator.clipboard.writeText(original.offerAddresses)}}>
                Copied
              </ReactTooltip>

              {original.offerAddresses.slice(0, 6)}...
            </Button>
        ),
      },
      {
        Header: "Token",
        id: 'token-token',
        accessor: (d) => d.lockedToken.symbol,
        Cell: ({ row: { original } }) => {
          const imgFile = `images/${original.lockedToken.symbol.toLowerCase()}.png`;
          return <img className="card-image mt-1" src={imgFile} />;
        },
      },
      {
        Header: "PRICE",
        id: '4',
        accessor: (d) => d.pricePerToken,
        Cell: ({ row: { original } }) => (<>${numberWithCommas(original.pricePerToken, true)}</>),
      },
      {
        Header: "TOKEN AMOUNT",
        id: 'token-amt-token',
        accessor: (d) => d.lockedBalances,
        Cell: ({ row: { original } }) => {
          return <>
            {numberWithCommas(original.lockedBalances, true)} <br/> <b>{original.lockedToken.symbol}</b>
          </>
        }
      },
      {
        Header: "TOKEN WANTED",
        id: 'token-wanted-token',
        accessor: (d) => d.amountWanted,
        Cell: ({ row: { original } }) => {
          return <>
            ${numberWithCommas(original.amountWanted, true)} <br/> <b>{original.tokenWantedSymbol}</b>
          </>
        }
      },
      {
        Header: " ",
        id: 'button-token',
        accessor: (d) => d.isUserOffer,
        Cell: ({ row: { original } }) => (
            <Button
                className="table-btn"
                onClick={() => onClick(original)}
            >
              {original.isUserOffer ? <>Cancel &<br /> Withdraw</> : <>Approve<br />& Buy</>}
            </Button>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    fetchTotalVolume();
    fetchData();
  }, []);

  return (
    <div className="market-main-div">
      <div className="market-head">
        <div className="market-head-content mt-2 intro-text text-center">
          FoxEscrow — The Leading Escrow Marketplace <br />
          <div className="intro-subtext">
            Trade Your Tokens and Items Safely with the Power of Audited Smart Contracts
          </div>
        </div>
        <div className="market-head-content">
          <img className="card-image mt-3" src={logo} />

          <div className="text-center p-3">
            <div className="card-body-text text-center">
              Total Traded Volume
            </div>
            <div className="total-volume p-3">
              $ {numberWithCommas(totalVolume, false)}
            </div>
          </div>

          <img className="card-image mt-3" src={logo} />
        </div>
      </div>
      <div className="market-btn-group">
        {/* <input type="button" class="btn btn-primary btn-md rounded-btn capitalize mb-10" value="Create Offer" /> */}
        <Button
          variant="primary"
          className="btn button2 rounded-btn btn-md market-btn"
          value="create offer"
          onClick={() => setModalShow(true)}
        >
          Create Offer
        </Button>

        <Dropdown className="dropdown-btn" onSelect={handleSelect}>
          <Dropdown.Toggle
            className="button padding rounded-btn text-white"
            variant="flat"
            id="dropdown-basic"
          >
            {sortStatus}
          </Dropdown.Toggle>
          <Dropdown.Menu className="bg-base-100">
            <Dropdown.Item
              vallue="all"
              key={"all"}
              eventKey="Show: ALL"
              className="item"
            >
              All
            </Dropdown.Item>
            {contractAddress.lockedTokens.map((row)=>
                <Dropdown.Item key={row.symbol} eventKey={`Filter: ${row.symbol}`} className="item">
                  {row.symbol}
                </Dropdown.Item>)
            }
          </Dropdown.Menu>
        </Dropdown>
        <InsufficientStableModal
            show={insufficientStable.active}
            data={insufficientStable}
            onHide={onHideInsufficientStable}
        />
        <CreatOfferModal
          show={modalShow}
          onHide={(isSuccess) => onHide(isSuccess)}
        />
      </div>

      <div className="market-body">
        {filterUserActiveoffers.length > 0 && (
          <>
            <h2 className="market-body-head">Your Offers</h2>
            <DataTable
              userContract={true}
              columns={columns}
              data={filterUserActiveoffers}
            />
          </>
        )}
        <h2 className="market-body-head">All Locked Token Offers</h2>
        <h5 className="market-body-tip">💡 Tip: Click column names to sort</h5>
        <DataTable
          columns={columns}
          filter={filterToken}
          data={filterActiveoffers}
        />
      </div>
    </div>
  );
}

export default ViewMarket;
