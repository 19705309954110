export const networks = {
    mainnet: {
        chainId: 1666600000,
        rpc: {1666600000: "https://api.harmony.one"},
        network: "one",
        qrcode: true
    }, 
    testnet: {
        rpc: {1666700000: "https://api.harmony.one"},
        chainId: 1666700000,
        network: 'one',
        qrcode: true
    }
}

export const activeNetwork = "mainnet"

