import { ethers } from "ethers";
import { activeNetwork, networks } from "../config/networkConfig";

export const PAGE_SIZE = 10;

export const value_sort = obj => {
    const sortedKeys = Object.keys(obj).sort((a, b) => a !== 'Bloater' && a.localeCompare(b));
    const output = {};
    sortedKeys.map(k => output[k] = obj[k]);
    return output;
}

export const item_sort = obj => {
    return obj.sort((a, b) => a.name !== 'Bloater' && a.name.localeCompare(b.name));
}

export function numberWithCommas(x, decimals) {
    if (x === '') {return '0'}
    if (x === 0 || x === '0.0') {return '0.00'}
    if (decimals) {
        if (Number(x) < 0.0001) {
            return Number(x).toLocaleString('en-US', { minimumFractionDigits: 8 });
        }
        if (Number(x) < 0.01) {
            return Number(x).toLocaleString('en-US', { minimumFractionDigits: 6 });
        }
        if (Number(x) < 0.1) {
            return Number(x).toLocaleString('en-US', { minimumFractionDigits: 5 });
        }
        if (Number(x) < 1) {
            return Number(x).toLocaleString('en-US', { minimumFractionDigits: 4 });
        }
        if (Number(x) < 10) {
            return Number(x).toLocaleString('en-US', { minimumFractionDigits: 3 });
        }
        return Number(x).toLocaleString('en-US', { maximumFractionDigits: 2 });
    }
    return parseInt(x).toLocaleString('en-US');
}


export function getItemImage(item) {
    if (item.symbol === "goldenegg" || item.symbol === "chaosstonelesser" || item.symbol === "shvasrune" ||
        item.symbol.includes('atonement') || item.itemType === ItemType.CRYSTAL || item.itemType === ItemType.STONE) {
        return `images/${item.symbol.toLowerCase()}.gif`
    }
    return `images/${item.symbol.toLowerCase()}.png`
}

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000"

export const provider = new ethers.providers.JsonRpcProvider(networks[activeNetwork]);

export const ItemType = {
    // TOKEN: "TOKEN",
    CRYSTAL: "CRYSTAL",
    STONE: "STONE",
    FISH: "FISH",
    OTHER: "OTHER",
    EGG: "EGG",
    // PLANT: "PLANT",
    POTION: "POTION",
    // RUNE: "RUNE"
}

const contractAddressTestnet = {
    multicallAddress: "0x414597a32aa9ce3e719ffebff9629c819ca4d1b1", // harmony network
    offerFactory:    "0xc12d028Bf980c10d7f3dc89deb8Fa6a92e34a5fd",
    lockedTokenLens: "0x550CBEB65928b5bd66E725b6094cDCE34FA3599C",
    itemOfferFactory: "0x012Ec04f66B2797B543E01dc79Bc1Ec7887c987F",
    itemLens: "0x2854cf639A538dc4B003C3bCdd2ea9F640f9ca38",
    lockedTokens: [
        { name: "JEWEL", address: "0x25Cb9C2720B88E336c374CF24be68D42bA7243A4", decimals: 18, symbol: "JEWEL" },
        { name: "VIPER", address: "0x4d378E5e189f435B3B1879772A2C2A4c76F5eA36", decimals: 18, symbol: "VIPER" }
    ],
    stableCoins: [
        { name: "USDC", address: "0xC6A6cD8E4a0134b37E3595DBac6f738970fC01A6", decimals: 18, symbol: "USDC" },
        { name: "BUSD", address: "0x3F9E6D6328D83690d74a75C016D90D7e26A7188c", decimals: 18, symbol: "BUSD" },
        { name: "UST", address: "0xE6FCfd410a993572713c47a3638478288d06aB2d", decimals: 18, symbol: "UST" },
        { name: "USDT", address: "0x4F97c0591b5fAb95a95d624BD34623A7ECF4ec07", decimals: 6, symbol: "USDT" }
    ],
    items: [
        { name: "Bloater", symbol: "Bloater", itemType: ItemType.FISH, address: "0x92Ec6BbBbaA21b01c30F1e6fAC7773FB208bfA13" },
        { name: "Golden Egg", symbol: "goldenegg", itemType: ItemType.EGG, address: "0xc1E54E68EB6DB74c6756Ba6C1B39332E0d2a1438" },
        { name: "Ragweed", symbol: "ragweed", itemType: ItemType.OTHER, address: "0xD1d89A186A702066d6A9D2b3aeac8f14A6096321" },
        { name: "Gold", symbol: "gold", itemType: ItemType.OTHER, address: "0x1cb90A7A399E22d4c41d4aFfCb35b4ADc45507dC" }

    ]
}


const contractAddressMainnet = {
    multicallAddress: "0xFE4980f62D708c2A84D3929859Ea226340759320",
    offerFactory:    "0x8773d7FCaC1A2811BCD29039331E9B0B9e584d00",
    lockedTokenLens: "0xD3e4637eFd21EaA295a64C56fbD8f5Fdf184e7f8",
    itemOfferFactory: "0x65EC385A6ab6f1081e8862c406c73f4edc6C0eEb",
    itemLens: "0x5BC6930864c10DbA1D774173F618D0f527aed48f",
    lockedTokens: [
        { name: "EVO", address: "0x5b747e23a9E4c509dd06fbd2c0e3cB8B846e398F", decimals: 18, symbol: "EVO" },
        { name: "JEWEL", address: "0x72Cb10C6bfA5624dD07Ef608027E366bd690048F", decimals: 18, symbol: "JEWEL" },
        { name: "VIPER", address: "0xEa589E93Ff18b1a1F1e9BaC7EF3E86Ab62addc79", decimals: 18, symbol: "VIPER" },
        { name: "MAGIC", address: "0x892D81221484F690C0a97d3DD18B9144A3ECDFB7", decimals: 18, symbol: "MAGIC" },
        { name: "BOSS", address: "0xE7bBE0E193FdFe95d2858F5C46d036065f8F735c", decimals: 18, symbol: "BOSS" },
        { name: "LOOT", address: "0xbDa99C8695986B45a0dD3979cC6f3974D9753D30", decimals: 18, symbol: "LOOT" },
        { name: "SONIC", address: "0x1e05C8B69e4128949FcEf16811a819eF2f55D33E", decimals: 18, symbol: "SONIC" },
    ],
    stableCoins: [
        { name: "USDC", address: "0x985458E523dB3d53125813eD68c274899e9DfAb4", decimals: 6, symbol: "USDC" },
        { name: "UST", address: "0x224e64ec1BDce3870a6a6c777eDd450454068FEC", decimals: 18, symbol: "UST" },
        { name: "BUSD", address: "0xE176EBE47d621b984a73036B9DA5d834411ef734", decimals: 18, symbol: "BUSD" },
        // { name: "JEWEL", address: "0x72Cb10C6bfA5624dD07Ef608027E366bd690048F", decimals: 18, symbol: "JEWEL" },

    ],
    items: [
        // Potion
        { name: "Anti-Blind Potion", symbol: "blindnesspotion", itemType: ItemType.POTION, address: "0x1771dEc8D9A29F30d82443dE0a69e7b6824e2F53" },
        { name: "Anti-Poison Potion", symbol: "antipoisonpotion", itemType: ItemType.POTION, address: "0xA1f8b0E88c51a45E152934686270DDF4E3356278" },
        { name: "Full Health Potion", symbol: "fullhealthpotion", itemType: ItemType.POTION, address: "0x87361363A75c9A6303ce813D0B2656c34B68FF52" },
        { name: "Health Potion", symbol: "healthpotion", itemType: ItemType.POTION, address: "0x2789F04d22a845dC854145d3c289240517f2BcF0" },
        { name: "Magic Resistance Potion", symbol: "magicresistpotion", itemType: ItemType.POTION, address: "0x7e120334D9AFFc0982719A4eacC045F78BF41C68" },
        { name: "Full Mana Potion", symbol: "fullmanapotion", itemType: ItemType.POTION, address: "0xDc2C698aF26Ff935cD1c50Eef3a4A933C62AF18D" },
        { name: "Mana Potion", symbol: "manapotion", itemType: ItemType.POTION, address: "0x19b020001AB0C12Ffa93e1FDeF90c7C37C8C71ef" },
        { name: "Stamina Potion", symbol: "staminapotion", itemType: ItemType.POTION, address: "0x959ba19508827d1ed2333B1b503Bd5ab006C710e" },
        { name: "Swiftness Potion", symbol: "swiftnesspotion", itemType: ItemType.POTION, address: "0x872dD1595544CE22ad1e0174449C7ECE6F0bb01b" },
        { name: "Toughness Potion", symbol: "toughnesspotion", itemType: ItemType.POTION, address: "0xFb03c364969a0bB572Ce62b8Cd616A7DDEb4c09A" },

        // EGG
        { name: "Blue Egg", symbol: "blueegg", itemType: ItemType.EGG, address: "0x9678518e04Fe02FB30b55e2D0e554E26306d0892" },
        { name: "Golden Egg", symbol: "goldenegg", itemType: ItemType.EGG, address: "0x9edb3Da18be4B03857f3d39F83e5C6AAD67bc148" },
        { name: "Green Egg", symbol: "greenegg", itemType: ItemType.EGG, address: "0x6d605303e9Ac53C59A3Da1ecE36C9660c7A71da5" },
        { name: "Grey Egg", symbol: "greyegg", itemType: ItemType.EGG, address: "0x95d02C1Dc58F05A015275eB49E107137D9Ee81Dc" },
        { name: "Yellow Egg", symbol: "yellowegg", itemType: ItemType.EGG, address: "0x3dB1fd0Ad479A46216919758144FD15A21C3e93c" },

        // Other
        // { name: "Gaia's Tears", symbol: "gaiastears", itemType: ItemType.OTHER, address: "0x24eA0D436d3c2602fbfEfBe6a16bBc304C963D04" },
        // { name: "Gold", symbol: "gold", itemType: ItemType.OTHER, address: "0x3a4EDcf3312f44EF027acfd8c21382a5259936e7" },
        { name: "Moksha rune", symbol: "moksharune", itemType: ItemType.OTHER, address: "0x8F655142104478724bbC72664042EA09EBbF7B38" },
        { name: "Shvas rune", symbol: "shvasrune", itemType: ItemType.OTHER, address: "0x66F5BfD910cd83d3766c4B39d13730C911b2D286" },

        // Crystal
        { name: "Lesser Chaos Crystal", symbol: "lesserchaoscrystal", itemType: ItemType.CRYSTAL, address: "0xa509c34306AdF6168268A213Cc47D336630bf101" },
        { name: "Chaos Crystal", symbol: "chaoscrystal", itemType: ItemType.CRYSTAL, address: "0x45B53E55b5c0A10fdd4fE2079a562d5702F3A033" },
        { name: "Lesser Might Crystal", symbol: "lessermightcrystal", itemType: ItemType.CRYSTAL, address: "0xaB464901AFBc61bAC440a97Fa568aC42885Da58B" },
        { name: "Might Crystal", symbol: "mightcrystal", itemType: ItemType.CRYSTAL, address: "0xb368f69bE6eDa74700763672AEB2Ae63f3d20AE6" },
        { name: "Lesser Finesse Crystal", symbol: "lesserfinessecrystal", itemType: ItemType.CRYSTAL, address: "0x39927A2CEE5580d63A163bc402946C7600300373" },
        { name: "Finesse Crystal", symbol: "finessecrystal", itemType: ItemType.CRYSTAL, address: "0xc6A58eFc320A7aFDB1cD662eaf6de10Ee17103F2" },
        { name: "Lesser Swiftness Crystal", symbol: "lesserswiftnesscrystal", itemType: ItemType.CRYSTAL, address: "0xf5c26F2F34E9245C3A9ea0B0e7Ea7B33E6404Da0" },
        { name: "Swiftness Crystal", symbol: "swiftnesscrystal", itemType: ItemType.CRYSTAL, address: "0x5d7f20e3B0f1406Bf038175218eA7e9B4838908c" },
        { name: "Lesser Vigor Crystal", symbol: "lesservigorcrystal", itemType: ItemType.CRYSTAL, address: "0x0d8403E47445DB9E316E36F476dacD5827220Bdd" },
        { name: "Vigor Crystal", symbol: "vigorcrystal", itemType: ItemType.CRYSTAL, address: "0xBbA50bD111DC586Fd1f2B1476B6eC505800A3FD0" },
        { name: "Lesser Fortitude Crystal", symbol: "lesserfortcrystal", itemType: ItemType.CRYSTAL, address: "0x3017609B9A59B77B708D783835B6fF94a3D9E337" },
        { name: "Fortitude Crystal", symbol: "fortcrystal", itemType: ItemType.CRYSTAL, address: "0x603919AEB55EB13F9CDE94274fC54ab2Bd2DecE7" },
        { name: "Lesser Wit Crystal", symbol: "lesserwitcrystal", itemType: ItemType.CRYSTAL, address: "0x17ff2016c9ecCFBF4Fc4DA6EF95Fe646D2c9104F" },
        { name: "Wit Crystal", symbol: "witcrystal", itemType: ItemType.CRYSTAL, address: "0x3619fc2386FbBC19DDC39d29A72457e758CFAD69" },
        { name: "Lesser Insight Crystal", symbol: "lesserinsightcrystal", itemType: ItemType.CRYSTAL, address: "0xc63b76f710e9973b8989678eb16234CfADc8D9DB" },
        { name: "Insight Crystal", symbol: "insightcrystal", itemType: ItemType.CRYSTAL, address: "0x117E60775584CdfA4f414E22b075F31cC9c3207C" },
        { name: "Lesser Fortune Crystal", symbol: "lesserfortunecrystal", itemType: ItemType.CRYSTAL, address: "0x13AF184aEA970Fe79E3BB7A1B0B156B195fB1f40" },
        { name: "Fortune Crystal", symbol: "fortunecrystal", itemType: ItemType.CRYSTAL, address: "0x6D777C64f0320d8A5b31BE0FdeB694007Fc3ed45" },
        { name: "Atonement Crystal", symbol: "atonementcrystal", itemType: ItemType.CRYSTAL, address: "0x27dC6AaaD95580EdF25F8B9676f1B984e09e413d" },
        { name: "Atonement Crystal Greater", symbol: "atonementcrystalgreater", itemType: ItemType.CRYSTAL, address: "0x17f3B5240C4A71a3BBF379710f6fA66B9b51f224" },
        { name: "Atonement Crystal Lesser", symbol: "atonementcrystallesser", itemType: ItemType.CRYSTAL, address: "0x1f3F655079b70190cb79cE5bc5AE5F19dAf2A6Cf" },

        // Stone
        { name: "Lesser Chaos Stone", symbol: "lesserchaosstone", itemType: ItemType.STONE, address: "0x6D4f4bC32df561a35C05866051CbE9C92759Da29" },
        { name: "Chaos Stone", symbol: "chaosstone", itemType: ItemType.STONE, address: "0x3633F956410163A98D58D2D928B38C64A488654e" },
        { name: "Lesser Might Stone", symbol: "lessermightstone", itemType: ItemType.STONE, address: "0xe4E7C0c693d8A7FC159776a993495378705464A7" },
        { name: "Might Stone", symbol: "mightstone", itemType: ItemType.STONE, address: "0xE7F6ea1cE7BbEbC9F2Cf080010dd938d2D8D8B1b" },
        { name: "Lesser Finesse Stone", symbol: "lesserfinessestone", itemType: ItemType.STONE, address: "0xbb5614D466b77d50DdEd994892DFe6F0ACA4eEbb" },
        { name: "Finesse Stone", symbol: "finessestone", itemType: ItemType.STONE, address: "0xD0B689Cb5DE0c15792Aa456C89D64038C1F2EedC" },
        { name: "Lesser Swiftness Stone", symbol: "lesserswiftnessstone", itemType: ItemType.STONE, address: "0xd9A8abC0Ce1ADC23F1c1813986c9a9C21C9e7510" },
        { name: "Swiftness Stone", symbol: "swiftnessstone", itemType: ItemType.STONE, address: "0x08f362517aD4119d93bBCd20825c2E4119abB495" },
        { name: "Lesser Vigor Stone", symbol: "lesservigorstone", itemType: ItemType.STONE, address: "0xB00CbF5Cd5e7b321436C2D3d8078773522D2F073" },
        { name: "Vigor Stone", symbol: "vigorstone", itemType: ItemType.STONE, address: "0x9df75917aC9747B4A70fa033E4b0182d85B62857" },
        { name: "Lesser Fortitude Stone", symbol: "lesserfortstone", itemType: ItemType.STONE, address: "0x1f57eb682377f5Ad6276b9315412920BdF9530f6" },
        { name: "Fortitude Stone", symbol: "fortstone", itemType: ItemType.STONE, address: "0x17Fa96ba9d9C29e4B96d29A7e89a4E7B240E3343" },
        { name: "Lesser Wit Stone", symbol: "lesserwitstone", itemType: ItemType.STONE, address: "0x4Ff7A020ec1100D36d5C81F3D4815F2e9C704b59" },
        { name: "Wit Stone", symbol: "witstone", itemType: ItemType.STONE, address: "0x939Ea05C81aAC48F7C10BdB08615082B82C80c63" },
        { name: "Lesser Insight Stone", symbol: "lesserinsightstone", itemType: ItemType.STONE, address: "0x762b98B3758d0A5Eb95B3E4A1E2914Ce0A80D99c" },
        { name: "Insight Stone", symbol: "insightstone", itemType: ItemType.STONE, address: "0x9D71Bb9C781FC2eBdD3d6cb709438e3c71200149" },
        { name: "Lesser Fortune Stone", symbol: "lesserfortunestone", itemType: ItemType.STONE, address: "0x6D6eA1D2Dc1Df6Eaa2153f212d25Cf92d13Be628" },
        { name: "Fortune Stone", symbol: "fortunestone", itemType: ItemType.STONE, address: "0x5da2EffE9857DcEcB786E13566Ff37B92e1E6862" },
        // Greater Stones
        { name: "Greater Might Stone", symbol: "greatermightstone", itemType: ItemType.STONE, address: "0x2bc05bf05E273a2276F19a8Bd6738e742A5685b3" },
        { name: "Greater Swiftness Stone", symbol: "greaterswiftnessstone", itemType: ItemType.STONE, address: "0xA1a56D20e4ba3fd2FB91c80f611ECa43c1311Afe" },
        { name: "Greater Vigor Stone", symbol: "greatervigorstone", itemType: ItemType.STONE, address: "0x00a2E2F8Feb81FDB7205992B5Abd2a801b419394" },
        { name: "Greater Finesse Stone", symbol: "greaterfinessestone", itemType: ItemType.STONE, address: "0x20f10ef23Cdc11Fa55E6B3703d88f19A7B345D15" },
        { name: "Greater Fortitude Stone", symbol: "greaterfortitudestone", itemType: ItemType.STONE, address: "0x27AF2A00B42Dcc0084A6Dc99169efbFE98eb140C" },
        { name: "Greater Wit Stone", symbol: "greaterwitstone", itemType: ItemType.STONE, address: "0xa6e86F2b43Ae73cfB09A3bA779AeB8Fd48417ba0" },
        { name: "Greater Insight Stone", symbol: "greaterinsightstone", itemType: ItemType.STONE, address: "0x40654Da5a038963fA9750AF352ae9d3b1da2baD0" },
        { name: "Greater Fortune Stone", symbol: "greaterfortunestone", itemType: ItemType.STONE, address: "0x7f26CB2BBBcFCE8e5866cc02a887A591E1Adc02A" },
        { name: "Greater Chaos Stone", symbol: "greaterchaosstone", itemType: ItemType.STONE, address: "0x2fB31FF9E9945c5c1911386865cD666b2C5dfeB6" },
        // Greater Crystals
        { name: "Greater Might Crystal", symbol: "gmightc", itemType: ItemType.CRYSTAL, address: "0xdFA5aE156AD4590A0061E9c4E8cc5bd60bc775c7" },
        { name: "Greater Finesse Crystal", symbol: "gfinessc", itemType: ItemType.CRYSTAL, address: "0xd1f789f6f8a3ee3fb94adBE3e82f43AAb51759Ee" },
        { name: "Greater Swiftness Crystal", symbol: "gswiftc", itemType: ItemType.CRYSTAL, address: "0x1e38e63227D52CBaDA2f0c11bE04feD64154ea37" },
        { name: "Greater Vigor Crystal", symbol: "gvigorc", itemType: ItemType.CRYSTAL, address: "0x5292dbce7eC2e10dd500984A163A5aE8abA585Ce" },
        { name: "Greater Fortitude Crystal", symbol: "gfortc", itemType: ItemType.CRYSTAL, address: "0xFE41BFf925eD88f688332b12746ef1Da68FD4CF2" },
        { name: "Greater Wit Crystal", symbol: "gwitc", itemType: ItemType.CRYSTAL, address: "0xbaAb8dB69a2FdC0b88B2B3F6F75Fa8899680c43B" },
        { name: "Greater Insight Crystal", symbol: "ginsightc", itemType: ItemType.CRYSTAL, address: "0x90454DbF13846CF960abc0F583c319B06aB3F280" },
        { name: "Greater Fortune Crystal", symbol: "gfortunec", itemType: ItemType.CRYSTAL, address: "0x2bC1112337B90bF8c5b9422bC1e98193a9C3d1f4" },
        { name: "Greater Chaos Crystal", symbol: "gchaosc", itemType: ItemType.CRYSTAL, address: "0x423bbec25e4888967baeDB7B4EC5b0465Fa3B87D" },
        // Plant
        // { name: "Ambertaffy", symbol: "ambertaffy", itemType: ItemType.PLANT, address: "0x6e1bC01Cc52D165B357c42042cF608159A2B81c1" },
        // { name: "Bluestem", symbol: "bluestem", itemType: ItemType.PLANT, address: "0xAC5c49Ff7E813dE1947DC74bbb1720c353079ac9" },
        // { name: "Darkweed", symbol: "darkweed", itemType: ItemType.PLANT, address: "0x68EA4640C5ce6cC0c9A1F17B7b882cB1cBEACcd7" },
        // { name: "Goldvein", symbol: "goldvein", itemType: ItemType.PLANT, address: "0x600541aD6Ce0a8b5dae68f086D46361534D20E80" },
        // { name: "Milkweed", symbol: "milkweed", itemType: ItemType.PLANT, address: "0xc0214b37FCD01511E6283Af5423CF24C96BB9808" },
        // { name: "Ragweed", symbol: "ragweed", itemType: ItemType.PLANT, address: "0x043F9bd9Bb17dFc90dE3D416422695Dd8fa44486" },
        // { name: "Redleaf", symbol: "redleaf", itemType: ItemType.PLANT, address: "0x094243DfABfBB3E6F71814618ace53f07362a84c" },
        // { name: "Rockroot", symbol: "rockroot", itemType: ItemType.PLANT, address: "0x6B10Ad6E3b99090De20bF9f95F960addC35eF3E2" },
        // { name: "Spiderfruit", symbol: "spiderfruit", itemType: ItemType.PLANT, address: "0x19B9F05cdE7A61ab7aae5b0ed91aA62FF51CF881" },
        // { name: "Swift-Thistle", symbol: "swiftthistle", itemType: ItemType.PLANT, address: "0xCdfFe898E687E941b124dfB7d24983266492eF1d" },

        // Rune
        // { name: "Shvas rune", symbol: "shvasrune", itemType: ItemType.OTHER, address: "0x66F5BfD910cd83d3766c4B39d13730C911b2D286" }

        // Fish
        { name: "Bloater", symbol: "bloater", itemType: ItemType.FISH, address: "0x78aED65A2Cc40C7D8B0dF1554Da60b38AD351432" },
        // { name: "Ironscale", symbol: "ironscale", itemType: ItemType.FISH, address: "0xe4Cfee5bF05CeF3418DA74CFB89727D8E4fEE9FA" },
        // { name: "Lantern Eye", symbol: "laterneye", itemType: ItemType.FISH, address: "0x8Bf4A0888451C6b5412bCaD3D9dA3DCf5c6CA7BE" },
        // { name: "Redgill", symbol: "redgill", itemType: ItemType.FISH, address: "0xc5891912718ccFFcC9732D1942cCD98d5934C2e1" },
        // { name: "Sailfish", symbol: "sailfish", itemType: ItemType.FISH, address: "0xb80A07e13240C31ec6dc0B5D72Af79d461dA3A70" },
        // { name: "ShimmerSkin", symbol: "shimmerskin", itemType: ItemType.FISH, address: "0x372CaF681353758f985597A35266f7b330a2A44D" },
        // { name: "Silverfin", symbol: "silverfin", itemType: ItemType.FISH, address: "0x2493cfDAcc0f9c07240B5B1C4BE08c62b8eEff69" },

    ]
}


export const contractAddress = contractAddressMainnet;

export const map = new Map();

contractAddress.lockedTokens.forEach((contract) => map.set(contract.address.toLowerCase(), contract));
contractAddress.stableCoins.forEach((contract) => map.set(contract.address.toLowerCase(), contract));
contractAddress.items.forEach((contract) => map.set(contract.address.toLowerCase(), contract));
