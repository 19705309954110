import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, NavDropdown, Navbar, Nav } from 'react-bootstrap';
import FoxLogo from '../assets/images/foxswap-thickwhite.png'
import FigureImage from "react-bootstrap/FigureImage";
import {Button} from "antd";
import {injected} from "../helper/connectors";

export default function Header(props) {
  const {
    active,
    error,
    account,
    library,
    connector,
    activate,
    deactivate,
    onActivate,
  } = useWeb3React();
  const [balance, setBalance] = useState(0);
  useEffect(async () => {
    if (!active) return;
    const balance = ethers.utils.formatEther(await library.getBalance(account));
    setBalance(parseFloat(balance).toFixed(2));
  }, [active]);

  const connect = async () => {
    await activate(injected);
    props.viewMarketClick();
  };

    return (
      <>
        <Navbar bg="dark" variant="dark" style={{fontSize: '18px'}}>
          <Container>
            <FigureImage height={'40px'} width={'40px'} src={FoxLogo} alt="" style={{ margin: '10px'}}/>
            <Nav className="me-auto" fill>
              <Nav.Item>
                <Nav.Link href="/">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/tokens">Locked Tokens</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/items">DFK Items</Nav.Link>
              </Nav.Item>
            </Nav>
            <Nav className="ms-auto" style={{alignItems: "center"}}>
              <NavDropdown title="Resources" id="basic-nav-dropdown" fill>
                <NavDropdown.Item href="https://foxswap.one">FoxSwap.one</NavDropdown.Item>
                <NavDropdown.Item href="https://twitter.com/FarmersOnlyFi">Twitter</NavDropdown.Item>
                <NavDropdown.Item href="https://discord.gg/foxswap">Manual Escrow (Discord)</NavDropdown.Item>
                <NavDropdown.Item href="https://discord.gg/foxswap">Discord</NavDropdown.Item>
                <NavDropdown.Item href="https://t.me/farmersonlyfi">Telegram</NavDropdown.Item>
                <NavDropdown.Item href="https://github.com/FarmersOnlyFi/fox-escrow-contracts/blob/main/audits/FoxEscrow_Smart_Contract_Audit_Report_-_QuillAudits.pdf">
                  Audit
                </NavDropdown.Item>
                <NavDropdown.Item href="/about">FAQ</NavDropdown.Item>
              </NavDropdown>

              <Nav.Item style={{alignItems: "center"}}>
                {active ? (
                      <Button
                          variant="primary"
                          style={{color: "white"}}
                          className="btn button rounded-btn btn-md"
                          onClick={connect}
                      >
                        Connected: {account.slice(0, 6)}...
                      </Button>
                ) : (
                    // <input type='button' className='btn button rounded-btn mt-4 capitalize btn-md mx-2 lg:w-18 text-xs' value='Connect Wallet' />
                    <Button
                        variant="primary"
                        style={{color: "white"}}
                        className="btn button rounded-btn btn-md"
                    >
                      Connect Wallet
                    </Button>
                )}
              </Nav.Item>
            </Nav>
          </Container>
        </Navbar>
      </>

    );
}
