import React, {useEffect, useState} from "react";
import "./index.scss";
import {
    Routes,
    Route
} from "react-router-dom";
import AboutUs from "./components/AboutUs";
import Header from "./components/Header";
import ViewMarket from "./components/ViewMarket";
import ConnectWallet from "./components/ConnectWallet";
import ViewItemMarket from "./components/ViewItemMarket";
import {useWeb3React} from "@web3-react/core";
import {ethers} from "ethers";
import {injected} from "./helper/connectors";

function App() {
    const {active, activate} = useWeb3React();
    const [ viewMarket, setViewMarket ] = useState(false);

    useEffect(async () => {
        await activate(injected);
        if (!active) return;
        setViewMarket(true);
    }, []);

    return (
        <>
        {viewMarket ?
        <Routes>
            <Route path="/" element={<>
                  <Header viewMarketClick={()=> setViewMarket(true)} />
                  <ViewMarket />
                </>
            } />
            <Route path="/tokens" element={<>
              <Header viewMarketClick={()=> setViewMarket(true)} />
                <ViewMarket />
            </>
            } />
            <Route path="/about" element={<>
              <Header viewMarketClick={()=> setViewMarket(true)} />
                <AboutUs />
                </>} />
            <Route path="/items" element={<>
              <Header viewMarketClick={()=> setViewMarket(true)} />
                <ViewItemMarket />
            </>} />
        </Routes> : <ConnectWallet viewMarketClick={()=> setViewMarket(true)} />}
        </>
    );
}

export default App;
