import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers-multicall";
import React, { useEffect, useState } from "react";
import {Button, Dropdown} from "react-bootstrap";
import logo from "../assets/images/foxswap.svg";
import ERC20Token from "../contracts/ERC20Token";
import LockedTokenLens from "../contracts/LockedTokenLens";
import ReactTooltip from 'react-tooltip';
import {
  ZERO_ADDRESS,
  contractAddress,
  value_sort,
  map as addressToContract,
  numberWithCommas,
  getItemImage,
  ItemType
} from "../helper/utils";
import OfferABI from "../contracts/abi/OfferABI.json";
import { initMultiCall } from "../contracts/multicall";
import OfferFactory from "../contracts/OfferFactory";
import CreateItemOfferModal from "./modal/CreateItemOfferModal";
import ItemDataTable from "./ItemDataTable";
import FillOfferModal from "./modal/FillOfferModal";
import OfferContract from "../contracts/Offer";

function filterOfferData(offeresData, sellersAddress, account) {
  if (offeresData.length === 0 || sellersAddress.length === 0) return offeresData;
  const userOffer = [];
  for (let i = 0; i < offeresData.length; i++) {
    if (sellersAddress[i].toLowerCase() === account.toLowerCase()) {
      const offerTemp = offeresData[i];
      offerTemp.isUserOffer = true;
      userOffer.push(offerTemp);
    }
  }
  return userOffer;
}

const DEFAULT_OFFER = {
  offerAddresses: '0x',
  itemBalances: '0',
  tokenWantedAddress: '0x',
  pricePerTokenInWei: '0',
  pricePerToken: 0,
  item: contractAddress.items[0],
  tokenWanted: 0,
  isUserOffer: false
}

function ViewItemMarket() {
  const [itemModalShow, setItemModalShow] = React.useState(false);
  const { library, account } = useWeb3React();
  const [activeoffers, setActiveOffer] = useState([]);
  const [userActiveoffers, setUserActiveOffer] = useState([]);
  const [totalVolume, setTotalVolume] = useState('');
  const [offeresData, setOffersData] = useState([]);
  const [sellersAddress, setSellersAddress] = useState([]);
  // Filters
  const [filterActiveoffers, setFilterActiveOffer] = useState([]);
  const [filterUserActiveoffers, setFilterUserActiveOffer] = useState([]);
  const [sortStatus, setSortStatus] = useState("All Items");
  const [filterItemType, setFilterItemType] = useState("All Types");
  const [filterToken, setFilterToken] = useState();
  const [fillModalShow, setFillModalShow] = React.useState({active: false, offer: DEFAULT_OFFER});

  const initialCounts = {}
  contractAddress.items.map(row => (
      initialCounts[row.name] = 0
  ));

  const [itemCount, setItemCount] = useState(initialCounts)

  function incrementCount(itemCount, itemName) {
    const temp = itemCount;
    temp[itemName] = temp[itemName]+ 1
    const tempSorted = value_sort(temp);
    setItemCount(tempSorted);
  }

  const fetchTotalVolume = async () => {
    try {
      const factory = new OfferFactory(
          contractAddress.itemOfferFactory,
          library.getSigner()
      );
      const totalVolume = await factory.totalVolume();
      setTotalVolume(totalVolume);
    } catch(err) {
      console.log('Error fetchTotalVolume:', err)
    }
  }

  const fetchData = async () => {
    try {
      const lockedTokenLens = new LockedTokenLens(
        contractAddress.itemLens,
        library.getSigner()
      );
      let data = await lockedTokenLens.getAllActiveOfferInfo(
        contractAddress.itemOfferFactory
      );

      let activeoffers_local = [];
      for (let i = 0; i < data[0].length; i++) {
        if (data[0][i] !== ZERO_ADDRESS) {
          const item = addressToContract.get(data[0][i].toLowerCase());
          const lockedBalance = ERC20Token.getFromWei(data[2][i].toString(), 0);
          const tokenWanted = addressToContract.get(data[3][i].toLowerCase());
          const pricePerToken = ERC20Token.getFromWei(
              data[4][i].toString(),
              tokenWanted.decimals
          );
          incrementCount(itemCount, item.name);
          activeoffers_local.push({
            offerAddresses: data[1][i],
            itemBalances: lockedBalance.toString(),
            tokenWantedAddress: data[3][i].toLowerCase(),
            pricePerTokenInWei: data[4][i].toString(),
            pricePerToken,
            item,
            tokenWanted,
            isUserOffer: false
          });
        }
      }
      setOffersData(activeoffers_local);
      setActiveOffer(activeoffers_local);
      // setFilterActiveOffer(activeoffers_local);
      const filterActiveData = activeoffers_local.filter((contract) => {
        return contract.item.name !== 'Bloater';
      });
      setFilterActiveOffer(filterActiveData);

      let sellers = activeoffers_local.map((ele) => {
        const contract = new Contract(ele.offerAddresses, OfferABI);
        return contract.seller();
      });
      const callInstance = await initMultiCall();
      const allSellers = await callInstance.all(sellers)
      setSellersAddress(allSellers);

      const tempUserActiveOffers = filterOfferData(activeoffers_local, allSellers, account)
      setFilterUserActiveOffer(tempUserActiveOffers);
      setUserActiveOffer(tempUserActiveOffers);
    } catch(err) {
      console.log('Error fetchTotalVolume:', err)
    }

  };

  const onHide = (isSuccess = false) => {
    if (isSuccess) fetchData(); fetchTotalVolume();
    setItemModalShow(false);
  };

  const onHideFillModal = () => {
    setFillModalShow({active: false, offer: DEFAULT_OFFER});
  };

  const onClick = async (offer) => {
    const offerContract = new OfferContract(
        offer.offerAddresses,
        library.getSigner()
    );
    if (offer.isUserOffer) {
      try {
        await offerContract.cancel();
      } catch (err) {
        alert(JSON.stringify(err));
        console.log(err);
      }
    } else {
      setFillModalShow({active: true, offer: offer});
    }
  }


  const handleSelect = (e) => {
    const tempUserData = userActiveoffers;
    const activeData = activeoffers;
    setSortStatus(e);
    const filterString = e;
    setFilterToken(e);
    const filterActiveData = activeData.filter((contract) => {
      if (filterString.toLowerCase() == "All Items".toLowerCase()) {
        return contract.item.name !== 'Bloater';
      } else {
        return contract.item.name === filterString;
      }
    });

    const filterUserData = tempUserData.filter((contract) => {
      if (filterString.toLowerCase() == "All Items".toLowerCase()) {
        return true;
      } else {
        return contract.item.name === filterString;
      }
    });
    setFilterActiveOffer(filterActiveData);
    setFilterUserActiveOffer(filterUserData);
  };

  const handleSelectType = (e) => {
    const tempUserData = userActiveoffers;
    const activeData = activeoffers;
    const filterItemString = e;
    setFilterItemType(e);
    const filterActiveData = activeData.filter((contract) => {
      if (filterItemString.toLowerCase() == "All Types".toLowerCase()) {
        return contract.item.name !== 'Bloater';
      } else {
        return contract.item.itemType === filterItemString;
      }
    });

    const filterUserData = tempUserData.filter((contract) => {
      if (filterItemString.toLowerCase() == "All Types".toLowerCase()) {
        return true;
      } else {
        return contract.item.itemType === filterItemString;
      }
    });
    setFilterActiveOffer(filterActiveData);
    setFilterUserActiveOffer(filterUserData);
  };

  const columns = React.useMemo(
      () => [
        {
          Header: "CONTRACT",
          id: "1",
          accessor: (d) => d.offerAddresses,
          Cell: ({ row: { original } }) => (
              <Button
                  className="button-invis"
                  data-tip="copy"
                  data-for={`registerTip-${original.offerAddresses}`}
                  data-event='click focus'
              >
                <ReactTooltip
                    id={`registerTip-${original.offerAddresses}`}
                    delayHide={1000}
                    afterShow={() => {
                      // console.log("clicked", original.offerAddresses)
                      navigator.clipboard.writeText(original.offerAddresses)}}>
                  Copied
                </ReactTooltip>

                  {original.offerAddresses.slice(0, 6)}...
              </Button>
          ),
        },
        {
          Header: "ITEM",
          id: "2",
          accessor: (d) => d.item.symbol,
          Cell: ({ row: { original } }) => {
            return <img className="card-image mt-1" src={getItemImage(original.item)} />;
          },
        },
        {
          Header: "ITEMS AVAILABLE",
          id: "3",
          accessor: (d) => d.itemBalances,
          Cell: ({ row: { original } }) => (
            <>
              {original.itemBalances}
              <br /> <b>{original.item.name}(s)</b>
            </>
          )
        },
        {
          Header: "PRICE/ITEM",
          id: "4",
          accessor: (d) => Number(d.pricePerToken),
          Cell: ({ row: { original } }) => (<>
              ${numberWithCommas(original.pricePerToken, true)} <br /> <b>{original.tokenWanted.symbol}</b>
              </>
          ),
        },
        {
          Header: " ",
          id: "5",
          accessor: (d) => d.isUserOffer,
          Cell: ({ row: { original } }) => (
              <Button
                  className="table-btn"
                  style={{alignItems: "center"}}
                  onClick={() => onClick(original)}
              >
                {original.isUserOffer ? <>Cancel &<br /> Withdraw</> : <>Buy</>}
              </Button>
          ),
        },
      ],
      []
  );

  useEffect(() => {
    fetchData();
    fetchTotalVolume();
    // handleSelect('All Items');
  }, []);

  return (
    <div className="market-main-div">
      <FillOfferModal
          offer={fillModalShow.offer}
          show={fillModalShow.active}
          onHide={onHideFillModal}
      />
      <div className="market-head">
        <div className="market-head-content mt-2 intro-text text-center">
          FoxEscrow — The Leading Escrow Marketplace <br />
          <div className="intro-subtext">
          Trade Your Tokens and Items Safely with the Power of Audited Smart Contracts
          </div>
        </div>
        <div className="market-head-content">
          <img className="card-image mt-3" src={logo} />

          <div className="text-center p-3">
            <div className="card-body-text text-center">
              Total Traded Volume
            </div>
            <div className="total-volume p-3">$ {numberWithCommas(totalVolume, false)}</div>
          </div>

          <img className="card-image mt-3" src={logo} />
        </div>
      </div>
      <div className="market-btn-group">
        {/* <input type="button" class="btn btn-primary btn-md rounded-btn capitalize mb-10" value="Create Offer" /> */}
        <Button
          variant="primary"
          className="btn button2 rounded-btn btn-md market-btn"
          value="create offer"
          onClick={() => setItemModalShow(true)}
        >
          Create Offer
        </Button>

        <Dropdown className="dropdown-btn" onSelect={handleSelect}>
          <Dropdown.Toggle
              className="button padding rounded-btn text-white"
              variant="flat"
              id="dropdown-basic"
          >
            {sortStatus}
          </Dropdown.Toggle>
          <Dropdown.Menu className="bg-base-100">
            <Dropdown.Item
                value="all"
                key={"all"}
                eventKey="All Items"
                className="item"
            >
              All Items
            </Dropdown.Item>
            {Object.entries(itemCount).map(([k,v])=>
                <Dropdown.Item key={k} eventKey={k} className="item">
                  {k} ({v})
                </Dropdown.Item>)
            }
            {/* <Dropdown.Divider className="border" /> */}
          </Dropdown.Menu>
        </Dropdown>

        {/* ITEM TYPE DROPDOWN FILTER*/}
        <Dropdown className="dropdown-btn" onSelect={handleSelectType}>
          <Dropdown.Toggle
              className="button padding rounded-btn text-white"
              variant="flat"
              id="dropdown-basic"
          >
            {filterItemType}
          </Dropdown.Toggle>
          <Dropdown.Menu className="bg-base-100">
            <Dropdown.Item
                value="All Types"
                key={"all types"}
                eventKey="All Types"
                className="item"
            >
              All Items
            </Dropdown.Item>
            {Object.entries(ItemType).map(([k,v])=>
                <Dropdown.Item key={v} eventKey={k} className="item">
                  {v}
                </Dropdown.Item>)
            }
          </Dropdown.Menu>
        </Dropdown>

        <CreateItemOfferModal
          show={itemModalShow}
          onHide={(isSuccess) => onHide(isSuccess)}
        />
      </div>

      <div className="market-body">
        {filterUserActiveoffers.length > 0 && (
            <>
              <h2 className="market-body-head">Your Offers</h2>
              <ItemDataTable
                  userContract={true}
                  columns={columns}
                  data={filterUserActiveoffers}
              />
            </>
        )}
        <h2 className="market-body-head">All Offers</h2>
        <h5 className="market-body-tip">💡 Tip: Click column names to sort</h5>
        <ItemDataTable
            columns={columns}
            filter={filterToken}
            data={filterActiveoffers}
        />
      </div>
    </div>
  );
}

export default ViewItemMarket;
