import React from "react";
import { Accordion, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/images/foxswap.svg";

function AboutUs() {
  return (
    <>
      <div className="flex px-2 mx-2 fixed mt-2 right-0 z-50">
        <Link to="/">
          <Button
            variant="primary"
            className="btn button rounded-btn btn-md side-button"
          >
            Home
          </Button>
        </Link>
      </div>
      <div className="h-screen bg-base-300 justify-items-center w-screen">
        <div className="flex grid justify-items-center ">
          <div className="text-3xl lg:text-6xl heroFont flex p-4">
            <div className="flex justify-center mt-4 me-5">
              <img src={logo} className="card-image" />
            </div>
            <p className="mt-4 text-5xl mr-10 ml-10 text-center">
              Frequently Asked Questions
            </p>
            <div className="flex justify-center mt-4 ps-5">
              <img src={logo} className="card-image" />
            </div>
          </div>
        </div>
        <div className="hero flex-col w-screen bg-base-300">
          <div className="hero-content flex flex-col mt-2">
            {/* <div className='block focus:outline-none p-3 w-1/2 bg-primary text-white w-full rounded-lg text-lg font-semibold mb-0' role="button">Q: What is 'OTC'? */}
            <Accordion
              className="block p-3 w-1/2 text-white w-full rounded-lg text-lg font-semibold "
              flush
            >
              <Accordion.Item eventKey="0" className="font-semibold text-white">
                <Accordion.Header>What is FOX Escrow?</Accordion.Header>
                <Accordion.Body className="bg-base-200 rounded-lg text-sm">
                  FOX Escrow is a utility intended to trustlessly facilitate
                  trades between buyers and sellers of various on-chain assets.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion
              className="block p-3 w-1/2 text-white w-full rounded-lg text-lg font-semibold mb-0 "
              flush
            >
              <Accordion.Item eventKey="0" className="font-semibold text-white">
                <Accordion.Header>How does FOX Escrow work?</Accordion.Header>
                <Accordion.Body className="bg-base-200 rounded-lg text-sm">
                  FOX Escrow works through smart contracts. Using our intuitive
                  interface, the seller is simply able to deploy a contract for the 
                  amount and type of asset they want to sell, along with their
                  desired amount and type of stablecoin they are willing to accept as
                  payment.
                  <br />
                  <br />  
                  When a suitable buyer deposits the necessary payment to fullfill
                  the contract, the assets placed in the contract are released to
                  the buyer, and the payment is released to the seller.
                  <br />
                  <br />  
                  The seller can also cancel the contract at any time before it is
                  fullfilled to get their assets back.
                  <br />  
                  <br />  
                  Disclaimer: Due to how locked tokens are implemented,
                  while deploying contracts for selling locked tokens,
                  please note:
                  <br />
                  1: Any unlocked tokens of the same type that you have
                  in your wallet will also be placed in the contract (so
                  make sure there isn't any in your wallet if you don't
                  wish to sell them.
                  <br />
                  2: You can NOT sell a partial amount of locked tokens.
                  If you wish to sell, you will have to sell the full amount.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion
              className="block p-3 w-1/2 text-white w-full rounded-lg text-lg font-semibold mb-0 "
              flush
            >
              <Accordion.Item eventKey="0" className="font-semibold text-white">
                <Accordion.Header>What are the fees?</Accordion.Header>
                <Accordion.Body className="bg-base-200 rounded-lg text-sm">
                  1.2% on locked token trades (such as locked JEWEL or VIPER)
                  <br />
                  2% on items
                  <br />
                  <br />
                  The above fees are deducted from the seller's final amount.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion
              className="block p-3 w-1/2 text-white w-full rounded-lg text-lg font-semibold mb-0 "
              flush
            >
              <Accordion.Item eventKey="0" className="font-semibold text-white">
                <Accordion.Header>Is FOX Escrow audited?</Accordion.Header>
                <Accordion.Body className="bg-base-200 rounded-lg text-sm">
                  A: Yes, our code is audited by QuillHash, audit report
                  available<a
                    rel="noreferrer"
                    target="_blank"
                    href="https://github.com/FarmersOnlyFi/fox-escrow-contracts/blob/main/audits/FoxEscrow_Smart_Contract_Audit_Report_-_QuillAudits.pdf"> <u>HERE</u>.</a>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion
              className="block p-3 w-1/2 text-white w-full rounded-lg text-lg font-semibold mb-0 "
              flush
            >
              <Accordion.Item eventKey="0" className="font-semibold text-white">
                <Accordion.Header>
                  Want to know more or request other tokens to be listed for escrow?
                </Accordion.Header>
                <Accordion.Body className="bg-base-200 rounded-lg text-sm">
                  If you have any other questions about our services, or if you
                  want to ask for other locked tokens to be made available for
                  trading at Fox Escrow, <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://discord.gg/3YnDhEU6gM"
                > <u>you can reach us on discord.</u></a>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* <span className='float-right'>
                                <svg stroke="currentColor" fill="white" stroke-width="0" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg" class="mt-1 h-4">
                                    <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z">

                                    </path>
                                </svg>
                            </span> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
