import React from "react";
import { Modal } from "react-bootstrap";
import {numberWithCommas} from "../../helper/utils";


function InsufficientStableModal(props) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="offer-modal pt-0">
                <Modal.Header closeButton className="offer-modal-header" onClick={props.onHide}>
                </Modal.Header>
                <Modal.Title className="offer-modal-title">
                    Insufficient {props.data.stableSymbol}!
                </Modal.Title>
                <Modal.Body className="offer-modal-body offer-coin-div">
                    <Modal.Title className="mb-1 ms-1 text-center">
                        Need: ${numberWithCommas(props.data.needed, true)} <br />
                        Have: ${numberWithCommas(props.data.wallet, true)}
                    </Modal.Title>

                </Modal.Body>
            </div>
        </Modal>
    );
}

export default InsufficientStableModal;
